import React, { useEffect } from 'react'
import './problems.scss'
import Aos from 'aos'
import Ligth from '../ligth/ligth'


const Problems = () => {
    useEffect(() => {
        Aos.init({ duration: 1000 })
      }, [])

    const problemsList = [{
        'img': 'icon1.png',
        'arrow': 'arrow2.png',
        'texts': ['Устали ходить за водой?',
            'Носить тяжелые бутылки?',
            'Пластик занимает много места?',
            'Контролировать остаток воды дома?']
    }, {
        'img': 'icon2.png',
        'arrow': 'arrow1.png',
        'texts': ['Устали от ожидания доставки воды?',
                'Нет возможности хранить пустые бутыли?',
                'Закончилась вода, а доставка еще не скоро?',
                'Надоело пользоваться помпой для налива воды?']
    }, {
        'img': 'icon3.png',
        'texts': ['Устали от высоких цен на воду?',
            'Вам не все равно на экологию планеты?',
            'Теряете много времени в очереди на кассе?',
            'Магазин далеко от дома, а за окном непогода?']
    }
    ]
    return (
        
        <div className='problemsDiv' id='problemsDiv'>
            <Ligth zIndex={-1} opacity={0.5} x={'-50%'} y={'0%'}/>
            <h1 className='mainHeader'>Вам это знакомо?</h1>
            <div className='problemsSection'>
                {problemsList.map(problems =>
                    <>
                        <div>
                            <img data-aos="fade-right" className='problemIcon' src={`/static/img/problems/${problems.img}`} alt="" />
                            <div>
                                {problems.texts.map(span => <span>{span}</span>)}
                            </div>
                        </div>
                        {problems.arrow &&
                            <div className="problemArrowDiv">
                                <img src={`/static/img/problems/${problems.arrow}`} alt="" />
                            </div>
                        }
                    </>

                )}
            </div>
            <Ligth zIndex={4} opacity={0.5} x={'-10%'} y={'-30%'} height={'500px'} width={'500px'}/>
            <div data-aos="zoom-out" className='solutionDiv'>
                    <h2>Есть решение - <h1> питьерра</h1></h2>
                    <p>Глобальная миссия нашей компании – забота о здоровье ваших семей и домашних питомцев. Мы предлагаем аренду станции с 5-ю ступенями очистки воды, которая сэкономит ваши деньги и сбережет здоровье.</p>
            </div>
            <Ligth zIndex={1} opacity={0.4} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} right={'0px'}/>
        </div>
    )
}

export default Problems