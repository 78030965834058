import React, { useEffect, useRef, useState } from 'react'
import './pay.scss'
import axios from "axios"
import Ligth from '../../elements/ligth/ligth'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Pay = () => {
    const [query, setQuery] = useState(null)
    const [month, setМonth] = useState(1)
    const [month8, setМonth8] = useState(1)

    const [stance, setStance] = useState(null)
    const [number, setNumber] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [stance8, setStance8] = useState(null)
    const [number8, setNumber8] = useState(null)
    const [name8, setName8] = useState(null)
    const [email8, setEmail8] = useState(null)

    const numberRef = useRef(null)
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const buttonRef = useRef(null)

    const numberRef8 = useRef(null)
    const nameRef8 = useRef(null)
    const emailRef8 = useRef(null)
    const buttonRef8 = useRef(null)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setQuery(params.get('pay'))
    }, [])

    const saveClient = () => {
        localStorage.setItem("stance", stance)
        localStorage.setItem("number", number)
        localStorage.setItem("name", name)
        localStorage.setItem("email", email)
        localStorage.setItem("stance8", stance8)
        localStorage.setItem("number8", number8)
        localStorage.setItem("name8", name8)
        localStorage.setItem("email8", email8)
    }

    const rememberClient = () => {
        var re_stance = localStorage.getItem("stance")
        var re_number = localStorage.getItem("number")
        var re_name = localStorage.getItem("name")
        var re_email = localStorage.getItem("email")
        var re_stance8 = localStorage.getItem("stance8")
        var re_number8 = localStorage.getItem("number8")
        var re_name8 = localStorage.getItem("name8")
        var re_email8 = localStorage.getItem("email8")
        if (re_stance) {
            setStance(re_stance)
        }
        if (re_number) {
            setNumber(re_number)
        }
        if (re_name) {
            setName(re_name)
        }
        if (re_email) {
            setEmail(re_email)
        }
        if (re_stance8) {
            setStance8(re_stance8)
        }
        if (re_number8) {
            setNumber8(re_number8)
        }
        if (re_name8) {
            setName8(re_name8)
        }
        if (re_email8) {
            setEmail8(re_email8)
        }
    }


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    useEffect(() => {
        rememberClient()
    }, [])


    const pay = () => {
        if (number && stance) {
            numberRef.current.classList.remove('error')
            if (name) {
                nameRef.current.classList.remove('error')
                if (email && validateEmail(email)) {
                    emailRef.current.classList.remove('error')
                    buttonRef.current.disabled = true

                    var token = getCookie('csrftoken')
                    axios.post("/api/pay", {
                        month: month,
                        number: `СО5№${stance}/${number}`,
                        name: name,
                        email: email,
                        type: 5,
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': token,
                        }
                    }).then(request => {
                        buttonRef.current.disabled = false
                        saveClient()
                        window.location.href = request.data
                    }).catch(request => {
                        buttonRef.current.disabled = false
                        nameRef.current.classList.add('error')
                        numberRef.current.classList.add('error')
                        emailRef.current.classList.add('error')
                        console.log(request.data)
                    })

                } else {
                    emailRef.current.classList.add('error')
                }
            } else {
                nameRef.current.classList.add('error')
            }
        } else {
            numberRef.current.classList.add('error')
        }
    }

    const pay8 = () => {
        if (number8 && stance8) {
            numberRef8.current.classList.remove('error')
            if (name8) {
                nameRef8.current.classList.remove('error')
                if (email8 && validateEmail(email8)) {
                    emailRef8.current.classList.remove('error')
                    buttonRef8.current.disabled = true

                    var token = getCookie('csrftoken')
                    axios.post("/api/pay", {
                        month: month8,
                        number: `СО8№${stance8}/${number8}`,
                        name: name8,
                        type: 8,
                        email: email8,
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': token,
                        }
                    }).then(request => {
                        buttonRef8.current.disabled = false
                        saveClient()
                        window.location.href = request.data
                    }).catch(request => {
                        buttonRef8.current.disabled = false
                        nameRef8.current.classList.add('error')
                        numberRef8.current.classList.add('error')
                        emailRef8.current.classList.add('error')
                        console.log(request.data)
                    })

                } else {
                    emailRef8.current.classList.add('error')
                }
            } else {
                nameRef8.current.classList.add('error')
            }
        } else {
            numberRef8.current.classList.add('error')
        }
    }

    return (
        <div className='payDiv'>
            <Helmet>
                <title>Оплата аренды системы очистки воды в Рязани в 3 клика</title>
                <meta name="description" content="Оплатить аренду станции за 1-6 месяцев в три клика. Выбрать период оплаты аренды станции, заполнить номер договора и ФИО. После этого выбрать способ и оплатить" />
            </Helmet>
            <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'300px'} width={'400px'} left={'0'} right={''} />

            {query === 'success' ?

                <div className="succesPayment">
                    <img src="/static/img/pay/logo.png" alt="" />

                    <h1 className='mainHeader'>внимание</h1>
                    <p>Оплата прошла успешно! <br /> Спасибо за выбор <b>ПИТЬЕРРА!</b></p>
                    <Link to="/">Перейти на сайт</Link>
                </div>
                :
                <>
                    <h1 className='mainHeader'>ОПЛАТА АРЕНДЫ в 3 клика</h1>
                    <div className="pay">
                        <div className='info'>
                            <div>
                                <span>Оплата для физических лиц:</span>
                                <p>В данном разделе Вы можете оплатить аренду станции за 1 - 6 месяцев в три клика. Для этого Вам необходимо выбрать период оплаты аренды станции, заполнить номер договора и ФИО. Далее при нажатии кнопки ОПЛАТИТЬ, Вы попадаете на вкладку СБЕРБАНК эквайринг, где Вы можете ввести данные любой банковской карты и оплатить аренду за период, который был выбран.</p>
                            </div>
                            <div>
                                <span>Оплата для юридических лиц:</span>
                                <p>Вам необходимо связаться с нами любым удобным для Вас способом, после чего мы выставим Вам счет на оплату того периода который Вам необходим.</p>
                            </div>

                            <h2>ОПЛАТА ПЯТИСТУПЕНЧАТАЯ СТАНЦИЯ</h2>
                            <div className='rangeInputSection'>
                                <div className='rangeInputDiv'>
                                    <input className="rangeInput" type="range" min="1" max="6" value={month} onChange={(e) => setМonth(e.target.value)} />
                                    <div>
                                        <span onClick={() => setМonth(1)}>1</span>
                                        <span onClick={() => setМonth(2)}>2</span>
                                        <span onClick={() => setМonth(3)}>3</span>
                                        <span onClick={() => setМonth(4)}>4</span>
                                        <span onClick={() => setМonth(5)}>5</span>
                                        <span onClick={() => setМonth(6)}>6</span>
                                    </div>
                                </div>
                                <div className="summDiv">
                                    <span>{month} месяц</span>
                                    <h4>{490 * month} руб</h4>
                                </div>
                            </div>
                            <div className="names">
                                <div>
                                    <span> Номер договора</span>
                                    <section ref={numberRef}>
                                        <h4>СО5№</h4>
                                        <input value={stance} onChange={(e) => setStance(e.target.value)} type="text" />
                                        <h4>/</h4>
                                        <input value={number} onChange={(e) => setNumber(e.target.value)} type="text" />
                                    </section>
                                </div>
                                <div>
                                    <span> ФИО</span>
                                    <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} placeholder='ФИО' type="text" />
                                </div>
                                <div>
                                    <span> Email</span>
                                    <input ref={emailRef} value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' type="text" />
                                </div>
                            </div>
                            <button ref={buttonRef} onClick={pay} className='payButton'>Оплатить</button>

                            <hr />

                            <h2>ОПЛАТА ВОСЬМИСТУПЕНЧАТАЯ СТАНЦИЯ</h2>
                            <div className='rangeInputSection'>
                                <div className='rangeInputDiv'>
                                    <input className="rangeInput" type="range" min="1" max="6" value={month8} onChange={(e) => setМonth8(e.target.value)} />
                                    <div>
                                        <span onClick={() => setМonth8(1)}>1</span>
                                        <span onClick={() => setМonth8(2)}>2</span>
                                        <span onClick={() => setМonth8(3)}>3</span>
                                        <span onClick={() => setМonth8(4)}>4</span>
                                        <span onClick={() => setМonth8(5)}>5</span>
                                        <span onClick={() => setМonth8(6)}>6</span>
                                    </div>
                                </div>
                                <div className="summDiv">
                                    <span>{month8} месяц</span>
                                    <h4>{690 * month8} руб</h4>
                                </div>
                            </div>
                            <div className="names">
                                <div>
                                    <span> Номер договора</span>
                                    <section ref={numberRef8}>
                                        <h4>СО8№</h4>
                                        <input value={stance8} onChange={(e) => setStance8(e.target.value)} type="text" />
                                        <h4>/</h4>
                                        <input value={number8} onChange={(e) => setNumber8(e.target.value)} type="text" />
                                    </section>
                                </div>
                                <div>
                                    <span> ФИО</span>
                                    <input ref={nameRef8} value={name8} onChange={(e) => setName8(e.target.value)} placeholder='ФИО' type="text" />
                                </div>
                                <div>
                                    <span> Email</span>
                                    <input ref={emailRef8} value={email8} onChange={(e) => setEmail8(e.target.value)} placeholder='Email' type="text" />
                                </div>
                            </div>

                            <button ref={buttonRef8} onClick={pay8} className='payButton'>Оплатить</button>

                        </div>
                        <div className="logo">
                            <div className='pitierra'>
                                <img src="/static/img/pay/logo.png" alt="" />
                            </div>
                            <div className='sber'>
                                <img src="/static/img/pay/ymoney.png" alt="" />
                            </div>
                            <div className='sber'>
                                <img src="/static/img/pay/tinkoff.png" alt="" />
                            </div>
                            <div className='sber'>
                                <img src="/static/img/pay/sber.png" alt="" />
                            </div>
                            <img className='arrow' src="/static/img/faq/arrow.png" alt="" />
                        </div>
                    </div>
                </>
            }
            <Ligth zIndex={-1} opacity={0.5} x={'0%'} y={'-50%'} height={'300px'} width={'400px'} right={'0'} />
        </div>
    )
}

export default Pay