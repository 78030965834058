import React, { useEffect, useState } from 'react'
import './benefice.scss'
import Aos from 'aos'
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const Benefice = () => {
    const [sliderCount, setSliderCount] = useState(3)
    const [examples, setExamples] = useState([])

    const getExamples = () => {
        axios.get('/api/examlpes').then(
            responce => {
                setExamples(responce.data)
            }
        ).catch(
            error => {
                console.log(error)
                setExamples([])
            }
        )
    }
    useEffect(() => {
        getExamples()
        Aos.init({ duration: 1500 })

        if (window.screen.width < 1800) {
            setSliderCount(3)
        }
        if (window.screen.width < 1440) {
            setSliderCount(2)
        }
        if (window.screen.width < 768) {
            setSliderCount(1)
        }
    }, [])
    return (
        <div className='beneficeDiv' id='beneficeDiv'>
            <div className='beneficeMask'>
            </div>
            {examples &&
                examples.length &&
                <>
                    <h1 data-aos="fade-left" className='mainHeader'>Примеры установок</h1>
                    <Swiper
                        className='examplesDiv'
                        autoplay
                        spaceBetween={30}
                        navigation={true}
                        pagination={true}
                        slidesPerView={sliderCount}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                    >
                        {examples.map(review =>
                            <SwiperSlide className='examples'>
                                <img src={`${review}`} alt="" />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </>
            }

            <h1 data-aos="fade-left" className='mainHeader'>Наше преимущество!</h1>
            <div className="beneficeBlock">
                <div className='beneficeBlockHeader'>
                    <img src="/static/img/benefice/family.png" alt="" />
                    <div className='beneficeBlockHeaderText'>
                        <h2>
                            <span>АРЕНДА</span> &nbsp;<h1 data-aos="fade-up" >16</h1>&nbsp; <span>руб. в день</span>
                        </h2>
                        <h2>
                            <span>или</span> &nbsp;<h1 data-aos="fade-up">490</h1>&nbsp; <span>руб. в месяц</span>
                        </h2>
                        <img src="/static/img/benefice/text.svg" alt="" />
                    </div>
                </div>
                <div className='beneficeBlocContext'>
                    <div>
                        <p>
                            <img src="/static/img/benefice/ico1.png" alt="" />
                            Оперативность
                            <span>Время с момента подачи заявки до установки системы не более – 2х дней.</span>
                        </p>
                        <p>
                            <img src="/static/img/benefice/ico2.png" alt="" />
                            Профессионализм
                            <span>Команда наших техников – опытные, добросовестные, опрятные люди.</span>
                        </p>
                        <p className='brd'>
                            <img src="/static/img/benefice/ico3.png" alt="" />
                            Установка
                            <span>Монтаж 5-ти ступенчатой системы очистки воды для вас будет БЕСПЛАТНО!</span>
                        </p>
                    </div>
                    <img src="/static/img/benefice/scheme.png" alt="" />
                    <div>
                        <p>
                            <img src="/static/img/benefice/ico4.png" alt="" />
                            Обслуживание
                            <span>Полная проверка и замена элементов фильтрации с последующей переработкой использованных картриджей.</span>
                        </p>
                        <p>
                            <img src="/static/img/benefice/ico5.png" alt="" />
                            Техподдержка
                            <span>Наши менеджеры проконсультируют вас по всем интересующим вопросам связанным с системой очистки.</span>
                        </p>
                        <p>
                            <img src="/static/img/benefice/ico6.png" alt="" />
                            ЭКО
                            <span>Нам не безразлично состояние экологии планеты. Меньше пластика – чище окружающая среда.</span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Benefice