import React, { useEffect, useState } from 'react'
import './calculator.scss'
import Ligth from '../ligth/ligth'
import Aos from 'aos'

const Calculator = () => {
    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])
    const [stage, setStage] = useState(1)
    const [activestage, setActiveStage] = useState(false)
    const [drops, setDrops] = useState([])
    const [activeDrops, setActiveDrops] = useState([])
    const [eco, setEco] = useState(null)
    const q1 = [
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
        { 'title': 4, 'selected': false },
        { 'title': 5, 'selected': false },
        { 'title': 6, 'selected': false },
        { 'title': 7, 'selected': false },
        { 'title': 8, 'selected': false }
    ]
    const q2 = [
        { 'title': 0, 'selected': true },
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
    ]
    const q3 = [
        { 'title': 0, 'selected': true },
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
    ]
    const q4 = [
        { 'title': 3, 'selected': false },
        { 'title': 4, 'selected': false },
        { 'title': 5, 'selected': false },
        { 'title': 6, 'selected': false },
        { 'title': 7, 'selected': false },
        { 'title': 8, 'selected': false },
        { 'title': 9, 'selected': false },
        { 'title': 10, 'selected': false },
        { 'title': 12, 'selected': false },
        { 'title': 15, 'selected': false },
    ]
    const q5 = [
        { 'title': 'Автомат розлива воды', 'selected': false, 'img': 'Frame.svg' },
        { 'title': 'Доставка на дом ', 'selected': false, 'img': 'Frame(1).svg' },
        { 'title': 'Магазин', 'selected': false, 'img': 'Frame(2).svg' },
    ]
    const [question1, setQuestion1] = useState([
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
        { 'title': 4, 'selected': false },
        { 'title': 5, 'selected': false },
        { 'title': 6, 'selected': false },
        { 'title': 7, 'selected': false },
        { 'title': 8, 'selected': false }
    ])
    const [question2, setQuestion2] = useState([
        { 'title': 0, 'selected': true },
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
    ])
    const [question3, setQuestion3] = useState([
        { 'title': 0, 'selected': true },
        { 'title': 1, 'selected': false },
        { 'title': 2, 'selected': false },
        { 'title': 3, 'selected': false },
    ])
    const [question4, setQuestion4] = useState([
        { 'title': 3, 'selected': false },
        { 'title': 4, 'selected': false },
        { 'title': 5, 'selected': false },
        { 'title': 6, 'selected': false },
        { 'title': 7, 'selected': false },
        { 'title': 8, 'selected': false },
        { 'title': 9, 'selected': false },
        { 'title': 10, 'selected': false },
        { 'title': 12, 'selected': false },
        { 'title': 15, 'selected': false },
    ])
    const [question5, setQuestion5] = useState([
        { 'title': 'Автомат розлива воды', 'selected': false, 'img': 'Frame.svg' },
        { 'title': 'Доставка на дом ', 'selected': false, 'img': 'Frame(1).svg' },
        { 'title': 'Магазин', 'selected': false, 'img': 'Frame(2).svg' },
    ])

    useEffect(() => {
        console.log(activestage)
    }, [activestage])


    function updateSelectedByTitle(titleToUpdate, newSelectedValue, func) {
        func(prevQuestion2 => {
            return prevQuestion2.map(item => {
                if (item.title === titleToUpdate) {
                    return { ...item, selected: newSelectedValue }
                }
                return { ...item, selected: false }
            });
        });
    }

    const reset = () => {
        setQuestion1(q1)
        setQuestion2(q2)
        setQuestion3(q3)
        setQuestion4(q4)
        setQuestion5(q5)
        setStage(1)
        setEco(null)
        setActiveStage(false)
    }
    const getTitles = (question) => {
        return question.filter(item => item.selected).map(item => item.title);
    };


    useEffect(() => {

        var p = getTitles(question1)
        var dog = getTitles(question2)
        var cat = getTitles(question3)
        var anim

        if (cat && dog) {
            anim = cat * 0.5 + dog * 2
        }
        else if (cat && !dog) {
            anim = cat * 0.5
        }
        else if (!cat && dog) {
            anim = dog * 2
        }
        else {
            anim = 0

        }
        var count = getTitles(question4)

        var e = getTitles(question5)
        var price
        if (e == 'Автомат розлива воды') {
            price = 6
        }
        else if (e == 'Доставка на дом ') {
            price = 16
        }
        else {
            price = 25
        }

        if (p && anim && count && price) {
            setEco(Math.round((p * count + anim) * price))
        }
        else if (p && count && price) {
            setEco(Math.round((p * count) * price))
        }        
    }, [question1,
        question2,
        question3,
        question4,
        question5,
        eco,])


    useEffect(() => {
        var active_drops_list = []
        for (let i = 0; i < stage; i++) {
            if (active_drops_list.indexOf(i + 1) === -1) {
                active_drops_list.push(i + 1)
            }
        }
        setActiveDrops(active_drops_list)
        var drops_list = []
        for (let i = 0; i < 5 - stage; i++) {
            if (drops_list.indexOf(i + 1) === -1) {
                drops_list.push(i + 1)
            }
        }
        setDrops(drops_list)

    }, [stage])

    function formatNumber(number) {
        let numberString = number.toString();
        let groups = [];
        while (numberString.length > 0) {
            groups.unshift(numberString.slice(-3));
            numberString = numberString.slice(0, -3);
        }
        return groups.join(' ');
    }


    return (
        <div className="calculatorDiv" id='calculatorDiv'>
            <div className="calculatorHeader">
                <div className='text'>
                    <p data-aos="fade-right" >сколько можно<br /> СЭКОНОМИТЬ <br />с нами?</p>
                    <div>
                        {activeDrops && activeDrops.map(drop =>
                            <img className='active' src="/static/img/calculator/active_drop.png" alt="" />
                        )}
                        {drops && drops.map(drop =>
                            <img src="/static/img/calculator/drop.png" alt="" />
                        )}
                    </div>
                </div>
                {stage === 5 ?
                    <img src="/static/img/calculator/happy_family.png" alt="" />
                    :
                    <img src="/static/img/calculator/family_talking.png" alt="" />
                }
            </div>
            <Ligth zIndex={0} opacity={0.5} x={'0%'} y={'-50%'} height={'500px'} width={'500px'} left={'0'} />
            <div className="calculatorBlock">
                {stage === 1 ?
                    <>
                        <p>
                            <img src="/static/img/calculator/stage1.svg" alt="" />
                            Количество человек в семье?
                        </p>
                        <div className="quizDiv quiz1">
                            {question1.map(quesion =>
                                <p onClick={(e) => {updateSelectedByTitle(quesion.title, true, setQuestion1);setActiveStage(true)}} className={`answer ${quesion.selected && 'answer_selected'}`}>{quesion.title}</p>
                            )}
                        </div>

                    </> :
                    stage === 2 ?
                        <>
                            <p>
                                <img src="/static/img/calculator/stage2.svg" alt="" />
                                Есть ли домашнее животное?
                            </p>
                            <div className="quizDiv quiz2">
                                <div className="animal">
                                    <img src="/static/img/calculator/dog.png" alt="" />
                                    <span>Собака</span>
                                </div>
                                {question2.map(quesion =>
                                    <p onClick={(e) => {updateSelectedByTitle(quesion.title, true, setQuestion2);setActiveStage(true)}} className={`answer ${quesion.selected && 'answer_selected'}`}>{quesion.title}</p>
                                )}
                            </div>
                            <div className="quizDiv quiz2">
                                <div className="animal">
                                    <img src="/static/img/calculator/cat.png" alt="" />
                                    <span>Кошка</span>
                                </div>
                                {question3.map(quesion =>
                                    <p onClick={(e) => {updateSelectedByTitle(quesion.title, true, setQuestion3);setActiveStage(true)}} className={`answer ${quesion.selected && 'answer_selected'}`}>{quesion.title}</p>
                                )}
                            </div>

                        </> :
                        stage === 3 ?
                            <>
                                <p>
                                    <img src="/static/img/calculator/stage3.svg" alt="" />
                                    Сколько литров воды вы расходуете в день для питья и приготовление еды?
                                </p>
                                <div className="quizDiv quiz4">
                                    {question4.map(quesion =>
                                        <p onClick={(e) => {updateSelectedByTitle(quesion.title, true, setQuestion4);setActiveStage(true)}} className={`answer ${quesion.selected && 'answer_selected'}`}>{quesion.title} <span>л</span> </p>
                                    )}
                                </div>

                            </> :
                            stage === 4 ?
                                <>
                                    <p>
                                        <img src="/static/img/calculator/stage4.svg" alt="" />
                                        Где вы покупаете воду?
                                    </p>
                                    <div className="quizDiv quiz5">
                                        {question5.map(quesion =>
                                            <p onClick={(e) => {updateSelectedByTitle(quesion.title, true, setQuestion5);setActiveStage(true)}} className={`answer ${quesion.selected && 'answer_selected'}`}>
                                                <img src={`/static/img/calculator/${quesion.img}`} alt="" />
                                                {quesion.title}
                                            </p>
                                        )}
                                    </div>

                                </> :
                                <>
                                    <p>
                                        <img src="/static/img/calculator/stage5.svg" alt="" />
                                        Экономия при аренде станции ПИТЬЕРРА
                                    </p>
                                    <div className="result">
                                        <img src="/static/img/calculator/icon1.svg" alt="" />
                                        <img src="/static/img/calculator/icon2.svg" alt="" />
                                        <div>
                                            <h5>Месяц</h5>
                                            <h4>+{formatNumber(eco * 30 - 490)} <span>руб.</span></h4>
                                        </div>
                                        <section className='line'></section>
                                        <div>
                                            <h5>Год</h5>
                                            <h4>+{formatNumber(eco * 365 - 5880)} <span>руб.</span></h4>
                                        </div>
                                        <section className='line'></section>
                                        <div>
                                            <h5>5 лет</h5>
                                            <h4>+{formatNumber(eco * 1825 - 29400)} <span>руб.</span></h4>
                                        </div>
                                    </div>

                                </>
                }
            </div>
            <div className='buttons'>
                {
                    stage === 1 ?
                        activestage ?
                            <button data-aos="fade-up" className='next' onClick={() => { setStage(stage + 1); setActiveStage(false) }}>Следующий вопрос</button>
                            :
                            <button data-aos="fade-up" className='next' disabled >Следующий вопрос</button>
                        :
                        stage === 2 ?
                            <>
                                <button className='back' onClick={() => setStage(stage - 1)}>Предыдущий вопрос</button>
                                <button data-aos="fade-up" className='next' onClick={() => { setStage(stage + 1) ;setActiveStage(false)}}>Следующий вопрос</button>
                            </>
                            :
                            stage === 3 ?
                                <>
                                    <button className='back' onClick={() => setStage(stage - 1)}>Предыдущий вопрос</button>
                                    {activestage ?
                                        <button data-aos="fade-up" className='next' onClick={() => { setStage(stage + 1); setActiveStage(false) }}>Следующий вопрос</button>
                                        :
                                        <button data-aos="fade-up" className='next' disabled >Следующий вопрос</button>

                                    }
                                </>
                                :
                                stage === 4 ?
                                    <>
                                        <button className='back' onClick={() => setStage(stage - 1)}>Предыдущий вопрос</button>
                                        {activestage ?
                                            <button data-aos="fade-up" className='next' onClick={() => { setStage(stage + 1); setActiveStage(false) }}>Результат</button>
                                            :
                                            <button data-aos="fade-up" className='next' disabled >Результат</button>}

                                    </>
                                    :
                                    stage === 5 &&
                                    <button className='next' onClick={reset}>Рассчитать заново</button>}
            </div>
            <Ligth zIndex={-1} opacity={0.5} x={'0%'} y={'100%'} height={'500px'} width={'500px'} right={'0px'} />
        </div>
    )
}

export default Calculator