import React, { useEffect, useRef, useState } from 'react'
import './loading.scss'

const Loading = () => {
    const [percent, setPercent] = useState(0)
    const loadingDiv = useRef(null)
    const box = useRef(null)
    const number = useRef(null)
    const text = useRef(null)


    useEffect(() => {
        if (window.location.hostname === 'localhost' ||window.location.hostname === '127.0.0.1'){
            loadingDiv.current.style.display = 'none'            

        }
        var cnt = document.getElementById("count");
        var water = document.getElementById("water");
        var percent = cnt.innerText;
        var interval;
        interval = setInterval(function () {
            setPercent(percent++)
            water.style.transform = 'translate(0' + ',' + (100 - percent) + '%)'
            if (percent == 100) {
                clearInterval(interval)
                setPercent(percent++)
                // loadingDiv.current.style.opacity = 0
                setTimeout(() => {
                    number.current.style.opacity = 0
                    text.current.style.opacity = 0
                    text.current.style.top = '50%'
                    setTimeout(() => {
                        box.current.classList.add("boxBig")
                        setTimeout(() => {
                            loadingDiv.current.style.opacity = 0
                            setTimeout(() => {
                                loadingDiv.current.style.display = 'none'
                            }, 550);
                        }, 500)
                    }, 500)
                }, 200)
            }
        }, 30);
    }, [])
    return (

        <div ref={loadingDiv} className='loadingDiv'>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{ display: 'none' }}>
                <symbol id="wave">
                    <path d="M420,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C514,6.5,518,4.7,528.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H420z"></path>
                    <path d="M420,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C326,6.5,322,4.7,311.5,2.7C304.3,1.4,293.6-0.1,280,0c0,0,0,0,0,0v20H420z"></path>
                    <path d="M140,20c21.5-0.4,38.8-2.5,51.1-4.5c13.4-2.2,26.5-5.2,27.3-5.4C234,6.5,238,4.7,248.5,2.7c7.1-1.3,17.9-2.8,31.5-2.7c0,0,0,0,0,0v20H140z"></path>
                    <path d="M140,20c-21.5-0.4-38.8-2.5-51.1-4.5c-13.4-2.2-26.5-5.2-27.3-5.4C46,6.5,42,4.7,31.5,2.7C24.3,1.4,13.6-0.1,0,0c0,0,0,0,0,0l0,20H140z"></path>
                </symbol>
            </svg>
            <div ref={box} class="box">
                <div ref={number} class="percent">
                    <div class="percentNum" id="count">{percent}</div>
                    <div class="percentB">%</div>
                </div>
                <div id="water" class="water">
                    <svg viewBox="0 0 560 20" className="water_wave water_wave_back">
                        <use xlinkHref="#wave"></use>
                    </svg>
                    <svg viewBox="0 0 560 20" className="water_wave water_wave_front">
                        <use xlinkHref="#wave"></use>
                    </svg>
                </div>
            </div>
            <p ref={text}>ТЕРРИТОРИЯ ЧИСТОЙ ВОДЫ</p>
        </div>
    )
}

export default Loading