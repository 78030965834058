import React from 'react'
import './head.scss'
import {Helmet} from 'react-helmet';

const Head = () => {
  return (
    <div className="headDiv" id='headDiv'>
      <Helmet>
        <title>Система очистки воды ПИТЬЕРРА в Рязани — домашняя станция</title>
        <meta name="description" content="Пятиступенчатая система очистки воды для домашней установки и использования. Аренда в Рязани — всего 490 руб/месяц. От заявки до установки — 2 дня. Тел: 522-922" />
      </Helmet>
      <div className="headtitles">
        <h1>питьерра</h1>
        <h5>Экономим деньги, бережём здоровье</h5>
      </div>
    </div>
  )
}

export default Head