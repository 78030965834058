import React, { useEffect } from 'react'
import Iframe from 'react-iframe'
import './footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
    useEffect(() => {
        if (window.location.hash) {
            window.location.href = window.location.hash
        }
    }, [])

    const locationTo = (location) => {
        window.location.href = location
    }
    return (
        <>
        <div className='smooth'></div>
            <div className='footer'>
                <div className="logo">
                    <h1>питьерра</h1>
                    <h4>Экономим деньги, бережём здоровье</h4>
                </div>
                <div className="routing">
                    <span>Содержание</span>
                    <Link to="/">Главная страница</Link>
                    <Link to="/reviews">Отзывы</Link>
                    <Link to="/contact">Контакты</Link>
                    <Link to="/pay">Оплата</Link>
                </div>
                <div className="routing">
                    <span>Разделы сайта</span>
                    <p onClick={() => locationTo('/#waveDiv')} >Система, которая работает.</p>
                    <p onClick={() => locationTo('/#beneficeDiv')} >Наше преимущество</p>
                    <p onClick={() => locationTo('/#calculatorDiv')} >Выгода</p>
                    <p onClick={() => locationTo('/#ourReviewsDiv')} >Оставить заявку</p>
                    <p onClick={() => locationTo('/#faqDiv')} >Вопросы / Ответы</p>
                </div>
                <div className="routing">
                    <span>Контакты</span>
                    <a href="tel:522922"> <img src="/static/img/footer/call.svg" alt="" />522-922</a>
                    <a href="mailto:watercode@gmail.com"> <img src="/static/img/footer/mail.svg" alt="" />info@pitierra.ru</a>
                    <a href=""> <img src="/static/img/footer/mark.svg" alt="" />390013, Рязанская область, г Рязань, ул Ситниковская, д. 69А , офис 22</a>
                    {/* <iframe src="" width="401" height="295" frameborder="0"></iframe> */}
                    <Iframe url="https://yandex.ru/map-widget/v1/?um=constructor%3A670bf24abf699cdeb179c672cb0834403e580a5f1b2d0371231c081cc2e44f8d&amp;source=constructor&style=stylers.lightness:invert"
                        display="block"
                        position="relative" />
                </div>
                <div className="bottom">
                    <div>
                        <span>
                            ООО "ПИТЬЕРРА"
                        </span>
                        <span>
                            ИНН: 6234205773
                        </span>
                        <span>
                            КПП: 623401001
                        </span>
                        <span>
                            ОГРН: 1236200008571
                        </span>
                        <hr />
                        <span>
                            <a target='_blank' href="/static/file/arend.pdf">Договор аренды</a>
                        </span>
                        <span>
                            <a target='_blank' href="/static/file/privacy_policy.pdf">Политика конфиденциальность</a>
                        </span>
                        <span>Все права защищены © {new Date().getFullYear()}.</span>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer

